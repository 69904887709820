import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useRef, useState } from "react";
import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
import Uploadfiles from "components/TranscriptionTool/Uploadfiles";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TranscriptionToolFaqData } from "components/FAQ/config";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import SEO from "components/seo";

const TranscriptionResult = () => {
  const [files, setFiles] = useState([]);
  const [outputText, setOutputText] = useState("");
  const [gptText, setGptText] = useState("");
  const [geminiText, setGeminiText] = useState("");
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const displayShareId = params.get("share-id");

  useEffect(() => {
    if (!displayShareId) return;
    let intervalId = null;
    const fetchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        setFiles(response);
        setInputLang(response?.meta?.language_code);
        console.log(response?.result?.result);
        setOutputText(response?.result?.result);
        setGeminiText(
          response?.result?.result?.gemini_result?.transcription?.transcription
        );
        setLoader(false);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
        setLoader(false);
      }
    };
    fetchAnalysis();
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [displayShareId]);

  const fileClean = () => {
    navigate(`/tools/ai-transcription`);
    setFiles("");
  };
  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );
  return (
    <Layout>
      <SEO
        title="Free Audio Video AI Transcription"
        description="Convert audio and video files to text instantly with the Tomedes AI Transcription Tool. Supports nearly 100 languages and multiple file formats. Fast, accurate, and free"
        slug="/tools/ai-transcription"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
          <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] pt-8  ">
            <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-1.5">
              AI Transcription
            </h1>
            <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
              Turn Audio into Text: Save Time, Share Ideas, and Simplify Your
              Workflow
            </h2>
            <div className="mt-4 flex lg:flex-row flex-col gap-4 p-4">
              <div className="relative shadow-box p-4 flex-[0.45] text-gray26 bg-white h-[456px] rounded-lg font-opensans flex flex-col justify-between">
                <div className="max-h-[175px]  w-full overflow-y-auto">
                  <div
                    className={`relative flex justify-between gap-1 items-center w-full border ${
                      files?.size > 25 * 1024 * 1024
                        ? "border-[#FFA4A4] bg-[#F0F5FF]"
                        : "border-[#2A7CF8] bg-[#F0F5FF]"
                    } mb-2 p-4 rounded-xl`}
                  >
                    <div className="flex justify-between w-full items-center gap-12">
                      <div className="flex items-center gap-4 font-arial w-full">
                        <div className="relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="34"
                            viewBox="0 0 26 34"
                            fill="none"
                          >
                            <path
                              d="M0 4.45833C0 3.50797 0.377529 2.59654 1.04953 1.92453C1.72154 1.25253 2.63297 0.875 3.58333 0.875H12.0938C12.2125 0.875 12.3265 0.922191 12.4105 1.00619C12.4945 1.09019 12.5417 1.20412 12.5417 1.32292V9.83333C12.5417 10.7837 12.9192 11.6951 13.5912 12.3671C14.2632 13.0391 15.1746 13.4167 16.125 13.4167H24.6354C24.7542 13.4167 24.8681 13.4639 24.9521 13.5479C25.0361 13.6319 25.0833 13.7458 25.0833 13.8646V29.5417C25.0833 30.492 24.7058 31.4035 24.0338 32.0755C23.3618 32.7475 22.4504 33.125 21.5 33.125H3.58333C2.63297 33.125 1.72154 32.7475 1.04953 32.0755C0.377529 31.4035 0 30.492 0 29.5417V4.45833Z"
                              fill="#9AB4DB"
                            />
                            <path
                              d="M14.3359 9.8327V1.95653C14.3358 1.86782 14.362 1.78107 14.4112 1.70726C14.4604 1.63346 14.5304 1.57592 14.6123 1.54195C14.6943 1.50799 14.7845 1.49911 14.8715 1.51646C14.9585 1.53381 15.0383 1.5766 15.101 1.63941L24.3209 10.8593C24.3837 10.922 24.4265 11.0018 24.4438 11.0888C24.4612 11.1758 24.4523 11.266 24.4183 11.348C24.3844 11.4299 24.3268 11.4999 24.253 11.5491C24.1792 11.5983 24.0925 11.6245 24.0038 11.6244H16.1276C15.6524 11.6244 15.1967 11.4356 14.8607 11.0996C14.5247 10.7636 14.3359 10.3079 14.3359 9.8327Z"
                              fill="#00173A"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.75 22.6505V19.2224H10.75V25.3341C10.7498 25.6878 10.623 26.0324 10.3879 26.3179C10.1528 26.6033 9.82157 26.8149 9.44225 26.9219C9.06292 27.0289 8.65512 27.0259 8.27787 26.9132C7.90061 26.8005 7.57347 26.5841 7.34378 26.2952C7.1141 26.0062 6.99378 25.6599 7.00025 25.3061C7.00671 24.9524 7.13962 24.6096 7.37975 24.3275C7.61987 24.0454 7.95475 23.8385 8.33592 23.7368C8.71709 23.635 9.12478 23.6438 9.5 23.7617V18.1112C9.5 17.8165 9.6317 17.5339 9.86612 17.3255C10.1005 17.1171 10.4185 17 10.75 17H15.75C16.0815 17 16.3995 17.1171 16.6339 17.3255C16.8683 17.5339 17 17.8165 17 18.1112V24.2229C16.9998 24.5766 16.873 24.9212 16.6379 25.2066C16.4028 25.4921 16.0716 25.7037 15.6922 25.8107C15.3129 25.9177 14.9051 25.9147 14.5279 25.802C14.1506 25.6893 13.8235 25.4729 13.5938 25.184C13.3641 24.895 13.2438 24.5486 13.2502 24.1949C13.2567 23.8412 13.3896 23.4984 13.6297 23.2163C13.8699 22.9342 14.2047 22.7273 14.5859 22.6255C14.9671 22.5238 15.3748 22.5326 15.75 22.6505Z"
                              fill="#0A2641"
                            />
                          </svg>
                        </div>

                        <p className="flex flex-col">
                          <span
                            className="text-[#0B0B0B] font-opensans text-sm font-normal"
                            style={{ wordBreak: "break-all" }}
                          >
                            {files?.meta?.file_info?.originalName}
                          </span>
                          <span
                            className={`text-[12px] font-opensans font-bold text-[#8B8B8B] uppercase `}
                          >
                            {files?.meta?.file_info?.documentSize}{" "}
                            <span className="ml-2.5">
                              {files?.meta?.file_info?.documentType}
                            </span>
                          </span>
                        </p>
                      </div>
                      <div
                        onClick={() => fileClean()}
                        className="cursor-pointer h-6 w-6 flex items-center justify-center bg-transparent filter-black"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M0.314703 0.814703C0.414208 0.714947 0.532416 0.6358 0.662555 0.581798C0.792695 0.527796 0.93221 0.5 1.07311 0.5C1.21401 0.5 1.35352 0.527796 1.48366 0.581798C1.6138 0.6358 1.73201 0.714947 1.83152 0.814703L7.50028 6.48561L13.169 0.814703C13.2686 0.715108 13.3869 0.636104 13.517 0.582204C13.6471 0.528303 13.7866 0.500561 13.9275 0.500561C14.0683 0.500561 14.2078 0.528303 14.3379 0.582204C14.468 0.636104 14.5863 0.715108 14.6859 0.814703C14.7855 0.914299 14.8645 1.03254 14.9184 1.16266C14.9723 1.29279 15 1.43226 15 1.57311C15 1.71396 14.9723 1.85343 14.9184 1.98356C14.8645 2.11368 14.7855 2.23192 14.6859 2.33152L9.01495 8.00028L14.6859 13.669C14.7855 13.7686 14.8645 13.8869 14.9184 14.017C14.9723 14.1471 15 14.2866 15 14.4275C15 14.5683 14.9723 14.7078 14.9184 14.8379C14.8645 14.968 14.7855 15.0863 14.6859 15.1859C14.5863 15.2855 14.468 15.3645 14.3379 15.4184C14.2078 15.4723 14.0683 15.5 13.9275 15.5C13.7866 15.5 13.6471 15.4723 13.517 15.4184C13.3869 15.3645 13.2686 15.2855 13.169 15.1859L7.50028 9.51495L1.83152 15.1859C1.73192 15.2855 1.61368 15.3645 1.48356 15.4184C1.35343 15.4723 1.21396 15.5 1.07311 15.5C0.932261 15.5 0.79279 15.4723 0.662663 15.4184C0.532535 15.3645 0.414299 15.2855 0.314703 15.1859C0.215108 15.0863 0.136104 14.968 0.0822038 14.8379C0.0283032 14.7078 0.000560843 14.5683 0.000560843 14.4275C0.000560843 14.2866 0.0283032 14.1471 0.0822038 14.017C0.136104 13.8869 0.215108 13.7686 0.314703 13.669L5.98561 8.00028L0.314703 2.33152C0.214947 2.23201 0.1358 2.1138 0.0817982 1.98366C0.0277962 1.85352 0 1.71401 0 1.57311C0 1.43221 0.0277962 1.2927 0.0817982 1.16256C0.1358 1.03242 0.214947 0.914208 0.314703 0.814703Z"
                            fill="#00173A"
                          />
                        </svg>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="16"
                          viewBox="0 0 9 16"
                          fill="none"
                        >
                          <path
                            d="M0.0998344 1.30763L1.4087 0L8.53777 7.1266C8.65269 7.24079 8.74389 7.37658 8.80612 7.52616C8.86835 7.67574 8.90039 7.83614 8.90039 7.99815C8.90039 8.16016 8.86835 8.32056 8.80612 8.47014C8.74389 8.61971 8.65269 8.75551 8.53777 8.8697L1.4087 16L0.101068 14.6924L6.7922 8L0.0998344 1.30763Z"
                            fill="#5B93FF"
                          />
                        </svg> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-sm">
                  <button
                    onClick={() => {
                      navigate(`/tools/ai-transcription?new_file=true`);
                      setFiles("");
                    }}
                    className="flex items-center gap-2 text-[#5B93FF] font-opensans font-semibold"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M8 1.5V15.5M1 8.5H15"
                        stroke="#5B93FF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Transcribe new file
                  </button>
                </div>
              </div>
              <TranscriptionTool
                outputText={outputText}
                loader={loader}
                error={error}
                inputLang={inputLang}
                displayShareId={displayShareId}
                gptText={gptText}
                geminiText={geminiText}
                setGeminiText={setGeminiText}
              />
            </div>
          </div>
          <p className="w-full text-center text-[12px] text-[#6E6E6E] font-opensans mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 max-w-5xl mx-auto md:mt-12 text-lg text-center font-opensans">
            This AI Transcription Tool makes it super easy to turn audio and
            video into text. Just upload your file—mp3, mp4, wav, and more—and
            get an instant, accurate transcription in almost 100 supported
            languages. No matter if you're working with interviews, meetings, or
            lectures, this tool makes your workflow easier with fast, reliable
            results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={TranscriptionToolFaqData} />
    </Layout>
  );
};

export default TranscriptionResult;
